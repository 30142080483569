@charset "utf-8";
@import '../../styles/style.scss';

.grabable-graph {
  border: 1px solid #000;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.grabable-graph:active {
  cursor: grabbing;
}

.non-grab:active {
  cursor: default;
}

.scatter-graph-bottom-button-spacer {
  display: flex;
  width: 100%;
  .right-first {
    margin-left: auto;
  }
  .icon-button {
    font-size: 150%;
  }
}
