@charset "utf-8";

// =======================
// Layout（一番大きな枠組み）
// =======================

* {
  overscroll-behavior-x: none !important;
}

// .btn {
//   box-shadow: none !important;
// }

select {
  box-shadow: none !important;
}

input {
  box-shadow: none !important;
}

.l-page {
  height: 100vh;

  .page_wrap {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    &.add_sidebar {
      overflow-y: initial;
    }

    &.coming_soon {
      height: 100vh;
    }

    &.login_wrap {
      width: 40%;
      margin: 0 auto;
      padding-top: 80px;
    }
  }
}

.twitter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}

.send-temporary-password-button {
  display: flex;
  justify-content: center;
}
