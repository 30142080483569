@charset "utf-8";
// =======================
// Base
// =======================
* {
  box-sizing: border-box;
}

body {
  background-color: $color_bg;
  color: $color_text;
  font-size: $base_font_size;
  font-family: $base_font;
  line-height: 1.4;
  text-size-adjust: 100%;
}

input,
textarea,
button,
pre {
  font-family: $base_font;
}

input,
textarea {
  font-size: $base_font_size;
}

select::-ms-expand {
  display: none;
}

:placeholder-shown,
::-webkit-input-placeholder {
  color: rgba(66, 105, 137, 0.3);
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

a {
  &:link {
    color: $link_color;
    text-decoration: none;
  }

  &:visited {
    color: $link_color_visited;
  }

  &:hover {
    color: $link_color_hover;
  }

  &:active {
    color: $link_color_active;
  }
}

canvas {
  vertical-align: bottom;
}

img {
  max-width: 100%;
  height: auto;
}

video {
  position: relative;
  vertical-align: top;
  width: 100%;
}

strong {
  font-weight: bold;
}

span {
  font-weight: inherit;
}

a,
area,
button,
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

area {
  outline: none;
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
  -webkit-appearance: none;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
p {
  margin-bottom: 0;
}

// Form Styles
.form-check-input {
  cursor: pointer;
  &:checked {
    background-color: #5c636a;
    border: #5c636a;

    &:hover {
      background-color: #94a9e0;
      border: #94a9e0;
    }

    &:focus {
      border: #5c636a;
    }
  }

  &:hover,
  &:focus {
    border: 1px solid #94a9e0;
  }
}

.form-control {
  color: $color_text;
  border: 2px solid #e5e5e5;

  &:focus,
  &:hover {
    border: 2px solid #94a9e0;
    box-shadow: none;
  }
}

.btn-close {
  &:focus,
  &:hover {
    box-shadow: none;
  }
}
