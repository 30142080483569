@charset "utf-8";
@import '../../styles/style.scss';

.character_talk-wrap {
  width: 100%;
  max-width: 700px;
  .character_talk {
    .mira_talk,
    .ao_talk {
      width: 600px;
      margin-left: 100px;
      padding: 16px 20px;
      border-radius: 8px;
      box-shadow: 0px 2px 6px -1px rgba(40, 40, 40, 0.25);
      position: relative;
      &:not(:first-child) {
        margin-top: 30px;
      }
      span {
        display: block;
        position: absolute;
        top: -5px;
        left: -78px;
        width: 50px;
        height: 50px;
        &::before {
          position: absolute;
          top: 6px;
          left: 0;
          z-index: 10;
          width: 50px;
          height: 50px;
          border-radius: 8px;
          overflow: hidden;
          background: no-repeat center center;
          background-size: 50px 50px;
          content: '';
        }
        &::after {
          display: block;
          position: absolute;
          bottom: -22px;
          left: 0;
          right: 0;
          margin: 0 -10px;
          font-size: 11px;
          text-align: center;
        }
      }
      &::before {
        position: absolute;
        top: 20px;
        left: -7px;
        width: 12px;
        height: 12px;
        margin: auto;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
        content: '';
      }
    }
    .mira_talk {
      background-color: #fff9f1;
      border: 1px solid #f6ad4d;
      &::before {
        background-color: #fff9f1;
        border-top: 1px solid #f6ad4d;
        border-right: 1px solid #f6ad4d;
      }
      span {
        &::before {
          background-image: url('../../images/character/coias_icon001.png');
          border: 1px solid #f6ad4d;
        }
        &::after {
          content: '木ノ幡みら';
        }
      }
    }

    .mira_talk_en {
      span {
        &::after {
          content: 'Mira';
        }
      }
    }

    .ao_talk {
      background-color: #f3f9ff;
      border: 1px solid #86b3e0;
      &::before {
        background-color: #f3f9ff;
        border-top: 1px solid #86b3e0;
        border-right: 1px solid #86b3e0;
      }
      span {
        &::before {
          background-image: url('../../images/character/coias_icon002.png');
          border: 1px solid #86b3e0;
        }
        &::after {
          content: '真中あお';
        }
      }
    }

    .ao_talk_en {
      span {
        &::after {
          content: 'Ao';
        }
      }
    }
  }
}
