@charset "utf-8";
@import '../../styles/style.scss';

.modal {
  .modal-content {
    .modal-header {
      background-color: $color_primary;
      .modal-title {
        color: $color_text_secondary;
        font-size: 20px;
        font-weight: 700;
      }
      .btn-close {
        filter: invert(100%) sepia(36%) saturate(301%) hue-rotate(235deg)
          brightness(118%) contrast(100%);
      }
    }
    .modal-body {
      &.contents-text_only {
        padding: 30px;
      }
      .label_text {
        font-weight: 700;
      }
    }
    .modal-footer {
      &.two_button {
        justify-content: space-between;
      }
      &.one_button {
        justify-content: center;
      }
    }
  }
}
