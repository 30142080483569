@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&family=Quicksand:wght@400;500;600;700&display=swap');
// =======================
//   Setting
// =======================

// MAX幅
$max_width: 1440px;

// MIN幅
$min_width: 768px;

// ベースフォント
$base_font_size: 16;
$base_font: 'Noto Sans JP', sans-serif;
$base_font_en: Quicksand, sans-serif;

// 背景色
$color_bg: #fff;
$color_bg_secondary: #f2f5fc;

// カラー
$color_primary: #6b80b6; // サイトのメインカラー
$color_secondary: #94a9e0; // サイトのセカンダリカラー
$color_text: #282828; // テキストベースカラー
$color_text_secondary: #fff; // テキストセカンダリカラー
$color_text_gray: #c4c4c4;

// リンクカラー
$link_color: $color_text;
$link_color_active: $color_secondary;
$link_color_hover: $color_secondary;
$link_color_visited: $color_text;

// ボーダー
$border_radius: 8px;
$border_size: 3px;

// Margin
$margin_xs: 10px;
$margin_sm: 20px;
$margin_md: 30px;
$margin_lg: 50px;
$margin_xl: 100px;

// Small devices (tablets, 544px and up)
// @media (min-width: $screen-sm-min) { ... }
$screen-sm-min: 34rem;

// Small devices (tablets, 768px and up)
// @media (min-width: $screen-md-min) { ... }
$screen-md-min: 48rem;

// Medium devices (desktops, 992px and up)
// @media (min-width: $screen-lg-min) { ... }
$screen-lg-min: 62rem;

// Large devices (large desktops, 1200px and up)
// @media (min-width: $screen-xl-min) { ... }
$screen-xl-min: 75rem;

// Large devices (large desktops, 1440px and up)
// @media (min-width: $screen-xxl-min) { ... }
$screen-xxl-min: 90rem;

// Large devices (large desktops, 1600px and up)
// @media (min-width: $screen-xxxl-min) { ... }
$screen-xxxl-min: 100rem;

// スクロールバー非表示
/* Chrome, Safari 対応 */
*::-webkit-scrollbar {
  display: none;
}
* {
  /* IE, Edge 対応 */
  -ms-overflow-style: none;
  /* Firefox 対応 */
  scrollbar-width: none;
}
