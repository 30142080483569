@charset "utf-8";
@import '../../styles/style.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 0 30px;
  z-index: 999;

  .offcanvas {
    align-items: flex-end;

    .header_links {
      display: flex;
      .link_style {
        color: #fff;

        &:link,
        &:visited {
          color: #fff;
        }
        &:hover,
        &:active {
          color: #fff;
          opacity: 0.7;
        }
        &:not(:last-child) {
          margin-right: $margin_sm;
        }
        .login_button {
          font-weight: 700;
          border: 1px solid #fff;
          border-radius: 8px;
          padding: 10px;
        }
      }

      .top_nav_link {
        height: 80px;
        padding: 28px 0;
      }

      .hovermenu_wrapper {
        position: relative;

        &:hover .hover_items,
        &:focus-within .hover_items {
          display: block;
        }
        &::after {
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
          content: '';
          display: inline-block;
          margin-left: 0.255em;
          vertical-align: 0.255em;
        }

        .hover_items {
          display: none;
          position: absolute;
          background: rgba(56, 85, 161, 0.95);
          top: 100%;
          padding: 10px;
          text-align: center;
          width: max-content;

          .hover_item:not(:first-child) {
            margin-top: 10px;
          }
        }
        input {
          display: none;
        }
        .sp_toggle {
          display: none;
        }
      }
    }
  }
  // Hamburger Icon
  .navbar-toggler {
    border: 1px solid #fff;

    &:focus {
      box-shadow: none;
    }

    .navbar-toggler-icon {
      filter: invert(100%) sepia(36%) saturate(301%) hue-rotate(235deg)
        brightness(118%) contrast(100%);
    }
  }
}
// Hamburger Menu
.modal-open {
  .offcanvas {
    background-color: #6b80b6;
    &.show {
      .btn-close {
        filter: invert(100%) sepia(36%) saturate(301%) hue-rotate(235deg)
          brightness(118%) contrast(100%);
      }
      .offcanvas-body {
        .navbar-nav {
          color: #fff;
          a {
            &:link,
            &:visited {
              color: #fff;
            }
            &:hover,
            &:active {
              opacity: 0.5;
            }
            &.login_font {
              color: #fff;
              font-size: 20px;
              font-weight: 700;
            }
          }
          .hovermenu_wrapper {
            position: relative;

            .hover_items {
              display: block;
              max-height: 0;
              overflow: hidden;
              margin-left: 1.75em;
              transition: max-height 0.35s ease-in-out;
            }
            input {
              display: none;
              appearance: button;
            }
            .sp_toggle {
              font-size: 1.5em;
              display: inline-block;
              padding: 0 7px 5px 7px;
              .when_off {
                display: block;
              }
              .when_on {
                display: none;
              }
            }
            input:checked ~ .hover_items {
              max-height: 100vh;
            }
            input:checked ~ .sp_toggle .when_off {
              display: none;
            }
            input:checked ~ .sp_toggle .when_on {
              display: block;
            }
            .hover_item:first-child {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}
