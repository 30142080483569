@charset "utf-8";

// =======================
// Unit（中間の枠組み）
// =======================

.u-page_title {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.coming_soon {
    .title_wrap {
      h1 {
        margin-bottom: $margin_sm;
      }
      p {
        text-align: center;
      }
    }
  }
}
.u-page_sub_title {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;

  &.login_title,
  &.faq_title {
    flex-flow: column;
    h3 {
      margin-bottom: $margin_xs;
    }
    p {
      text-align: center;
    }
  }
  &.login_title {
    height: 220px;
    padding-top: 0;
  }
}
.u-contents_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 100px 0;

  .content_title {
    margin-bottom: $margin_xl;
  }

  .contents_text {
    width: 100%;
    max-width: 700px;
    text-align: justify;

    .contents_text-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .contents_text-detail {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .list_content {
      &.about_coias {
        margin-bottom: $margin_lg;
        .list_content-title {
          padding: 0.2em 0.5em;
          margin-bottom: $margin_sm;
          border-left: solid 5px $color_secondary;
        }
        .step_title {
          // font-size: 20px;
          font-weight: 700;
          margin-right: $margin_sm;
          color: $color_primary;
        }
      }
      &.regulation {
        &:not(:last-child) {
          margin-bottom: $margin_lg;
        }
        .list_ml {
          margin-left: 20px;
        }
        .list_mb {
          margin-bottom: $margin_sm;
        }
      }
    }

    // Data Analysis
    .data_analysis {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: $margin_lg;
      }
      .data_analysis-title {
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        margin-bottom: $margin_md;
      }
      .number_wrap {
        text-align: center;
        .prefix {
          font-size: 25px;
          vertical-align: 6px;
        }
        .number {
          margin-right: $margin_xs;
          font-family: $base_font_en;
          font-size: 64px;
          font-weight: 700;
          color: $color_primary;
          line-height: 0.7;
        }
        .unit {
          font-size: 32px;
          vertical-align: 6px;
        }
      }
    }

    // 仮符号リストの位置決め(手動調整)
    .data_analysis_provisional_table {
      text-align: left;
      font-size: 85%;
      .table td {
        padding: 8px;
      }
      input.form-control {
        font-size: 85%;
      }
      th span.caret {
        display: inline-block;
        margin-left: 2px;
        vertical-align: middle;
        content: '';
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
      th span.dropup span.caret {
        display: inline-block;
        margin-left: 2px;
        vertical-align: middle;
        content: '';
        border-bottom: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        border-top: 0px;
      }
      .dropdown-menu {
        min-width: 1rem;
        li.dropdown-item {
          padding: 0;
          a {
            display: block;
            padding: 2px 20px;
          }
        }
      }
      .btn {
        padding: 0 15px;
      }
      /* screenreader */
      .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    }

    // Table Style
    .table {
      --bs-table-color: #282828;
      --bs-table-bg: transparent;
      --bs-table-border-color: #282828;
      --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
      --bs-table-striped-color: #282828;
      --bs-table-striped-bg: transparent;
      --bs-table-active-color: #282828;
      --bs-table-active-bg: transparent;
      --bs-table-hover-color: #282828;
      --bs-table-hover-bg: transparent;
      border-right: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      tbody {
        tr {
          th,
          td {
            padding: 10px 12px;
            border-left: 1px solid #e5e5e5;
            border-top: 1px solid #e5e5e5;
          }
        }
      }
    }

    // FAQ Style
    &.faq_wrap {
      .faq_contents {
        &:not(:last-child) {
          margin-bottom: $margin_lg;
        }
        .accordion {
          width: 700px;
          .accordion-item {
            border: none;
            border-radius: 8px;
            &:not(:last-child) {
              margin-bottom: $margin_sm;
            }
            .accordion-header {
              .accordion-button {
                border-radius: 8px;
                &:hover,
                &:active,
                &:focus {
                  box-shadow: none;
                }
                &::after {
                  filter: invert(47%) sepia(23%) saturate(694%)
                    hue-rotate(185deg) brightness(102%) contrast(96%);
                }
                &:not(.collapsed) {
                  color: $color_text;
                  background-color: #fff;
                  box-shadow: none;
                }
                .question_text {
                  position: relative;
                  margin: 0;
                  padding: 5px 0 5px 2em;
                  &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: 'Q';
                    font-size: 24px;
                    font-family: $base_font_en;
                    line-height: 1.2;
                    color: $color_text_secondary;
                    font-weight: 700;
                    -webkit-text-stroke: 1px $color_primary;
                    text-stroke: 1px $color_primary;
                  }
                }
              }
            }
            .accordion-collapse {
              .accordion-body {
                .answer_text {
                  position: relative;
                  margin: 0;
                  padding-left: 2em;
                  color: $color_primary;
                  font-weight: 700;
                  &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: 'A';
                    font-size: 24px;
                    font-family: $base_font_en;
                    line-height: 1.2;
                  }
                  a {
                    color: $color_primary;
                    text-decoration: underline;
                    &:hover {
                      color: $color_secondary;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .cooperate {
      border-top: 1px solid $color_text;
      padding-top: 10px;
    }
  }
  .contents_movie {
    position: relative;
    max-width: 100%;
    padding-top: 56.25%;
    .movie_text {
      width: 100%;
      text-align: center;
      color: $color_primary;
      font-weight: 700;
      margin-bottom: $margin_xs;
    }
    .movie_wrap {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }

  .detail_buttons_wrap {
    padding-top: 50px;
    :not(:last-child) {
      margin-bottom: $margin_sm;
    }
    .description {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      &::before,
      &::after {
        content: '';
        width: 3px;
        height: 24px;
        background-color: $color_primary;
      }
      &::before {
        margin-right: $margin_sm;
        transform: rotate(-35deg);
      }
      &::after {
        margin-left: 20px;
        transform: rotate(35deg);
      }
    }
    .btn_wrap {
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  }

  // News Style
  .news_contents {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    max-width: 700px;
    width: 100%;
    margin-bottom: $margin_lg;
    .news_img {
      width: auto;
      height: auto;
      max-width: 400px;
      max-height: 100%;
      object-fit: contain;
    }
    a {
      text-decoration: underline;
    }
  }
  .top_shortnews_contents {
    align-items: flex-start;
    max-width: 700px;
  }

  // Contact Style
  .contact_wrap {
    width: 500px;
    margin-bottom: $margin_lg;
    .contact_textarea {
      height: 200px;
    }
  }

  // Dictionary and Procedure
  .add_sidebar {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20%);
    margin: 0 auto;

    .contents_wrap {
      width: 100%;
      text-align: justify;
      .glossary_wrap {
        &:not(:last-child) {
          margin-bottom: $margin_lg;
        }
        .glossary_wrap-title {
          position: relative;
          font-weight: 700;
          font-size: 20px;
          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            position: absolute;
            bottom: -5px;
            left: 0px;
          }
        }
        &:nth-child(2n + 1) {
          .glossary_wrap-title {
            &::first-letter {
              font-size: 32px;
            }
          }
        }
        &:nth-child(2n) {
          .glossary_wrap-title {
            &::first-letter {
              font-size: 32px;
            }
          }
        }
        .glossary_wrap-text {
          padding-top: 13px;
          a {
            text-decoration: underline;
          }
          .text_lists {
            .lists_indent {
              margin-left: 30px;
              margin-bottom: $margin_xs;
            }
          }
        }
      }
      &.dictionary {
        .glossary_wrap {
          .glossary_wrap-title {
            &::before {
              border-bottom: 3px dashed $color_secondary;
            }
          }
          &:nth-child(2n + 1) {
            .glossary_wrap-title {
              &::first-letter {
                color: $color_secondary;
              }
            }
          }
          &:nth-child(2n) {
            .glossary_wrap-title {
              &::first-letter {
                color: $color_text_secondary;
                text-shadow: 1px 1px 0 $color_secondary,
                  -1px -1px 0 $color_secondary, -1px 1px 0 $color_secondary,
                  1px -1px 0 $color_secondary, 0px 1px 0 $color_secondary,
                  0-1px 0 $color_secondary, -1px 0 0 $color_secondary,
                  1px 0 0 $color_secondary;
              }
            }
          }
        }
      }
      &.procedure {
        .glossary_wrap {
          .glossary_wrap-title {
            &::before {
              border-bottom: 3px dashed $color_primary;
            }
          }
          .glossary_wrap-text {
            a {
              color: $color_text;
              text-decoration: underline;
              cursor: pointer;
              &:hover {
                color: $color_secondary;
              }
            }
          }
          &:nth-child(2n + 1) {
            .glossary_wrap-title {
              &::first-letter {
                color: $color_primary;
              }
            }
          }
          &:nth-child(2n) {
            .glossary_wrap-title {
              &::first-letter {
                color: $color_text_secondary;
                text-shadow: 1px 1px 0 $color_primary,
                  -1px -1px 0 $color_primary, -1px 1px 0 $color_primary,
                  1px -1px 0 $color_primary, 0px 1px 0 $color_primary,
                  0-1px 0 $color_primary, -1px 0 0 $color_primary,
                  1px 0 0 $color_primary;
              }
            }
          }
        }
      }
    }
  }
}

// Login Style
.u-login_contents {
  margin-bottom: $margin_md;
  .contents_wrap {
    padding: 50px 75px;
    .modal_open-password {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      p {
        color: $color_primary;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          opacity: 0.6;
        }
      }
    }
    .btn_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.guide_caution {
  font-size: 24px;
  text-align: left;
  margin-bottom: $margin_xl;
  margin-top: -60px;
  width: 100%;
  max-width: 700px;
}
.guide-ul {
  li {
    list-style-type: square;
    a {
      text-decoration: underline;
    }
  }
}

// Send MPC Converter

// Converter.scss
.sendmpc-converter-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .contents_text {
    text-align: center;
  }

  .textarea-wrapper {
    position: relative;
    width: 100%;
    margin: 10px 0;
  }

  textarea {
    width: 100%;
    height: 200px;
    font-size: 0.8rem;
    border-radius: 5px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid rgba(107, 128, 182, 1);
    resize: none; // ユーザがテキストエリアをリサイズできないようにする
    -ms-overflow-style: scrollbar;
    scrollbar-width: auto;
  }

  textarea::-webkit-scrollbar {
    display: block;
  }
  textarea.result-textarea {
    background-color: #e0e0e0;
  }

  button {
    margin: 20px 0;
  }
  .copy-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    font-size: 0.8rem;
    color: white;
    background: #555;
    border-radius: 2px 5px 2px 2px;

    .checkmark {
      color: #43e367;
      font-size: 1.1em;
      position: absolute;
      top: 50%;
      left: 90%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.5s;
      &.copied {
        opacity: 1;
      }
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background-color: #0000004f;
  top: 0;
  left: 0;

  .spinner {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
  }
}

// データ解析状況ページの詳細ボタンの配置レイアウト
.data_analysis-detail-buttons {
  display: flex;
  justify-content: center;
  align-self: auto;
  gap: 10px;
  button {
    height: 60px;
  }
}
